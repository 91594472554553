import { Component, Vue, Emit } from 'vue-property-decorator';
import VueRouter, { Route } from 'vue-router';
import store from '@/store';
import axios, {AxiosResponse, AxiosError} from 'axios';
import { GetAccountResponse, ErrorResponse } from '../../types/typescript-axios/api';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
]);
@Component
export default class AccountForm extends Vue {
  private fromPath: string = '';

  private agree: boolean = false;
  private mail: string = '';
  private name1: string = '';
  private name2: string = '';
  private birthYear: string = '';
  private companyName: string = '';
  private companyOptions: string[] = [];
  private departmentName: string = '';
  private sex: number = 0;
  private consent: number = 0;
  private password: string = '';
  private newPassword: string = '';
  private mailReInput: string = '';

  private passwordReInput: string = '';
  private newPasswordReInput: string = '';
  private secretQuestAans: string = '';
  private tel1: string = '';
  private tel2: string = '';
  private tel3: string = '';

  get birthdayOptions(): number[] {
    const array: number[] = [];
    const startYear: number = 1920;
    const nowYear: number = new Date().getFullYear();
    for (let i = 1920; i < nowYear; i++) {
      array.unshift(i);
    }
    return array;
  }

  @Emit()
  private setAgree(value: boolean): void {
    this.agree = value;
  }

  @Emit()
  private setMail(value: string): void {
    this.mail = value;
  }

  @Emit()
  private setName1(value: string): void {
    this.name1 = value;
  }

  @Emit()
  private setName2(value: string): void {
    this.name2 = value;
  }

  @Emit()
  private setBirthYear(value: string): void {
    this.birthYear = value;
  }

  @Emit()
  private setCompanyName(value: string): void {
    this.companyName = value;
  }

  @Emit()
  private setDepartmentName(value: string): void {
    this.departmentName = value;
  }

  @Emit()
  private setSex(value: number): void {
    this.sex = value;
  }

  @Emit()
  private setConsent(value: number): void {
    this.consent = value;
  }

  @Emit()
  private setPassword(value: string): void {
    this.password = value;
  }

  @Emit()
  private setNewPassword(value: string): void {
    this.newPassword = value;
  }

  @Emit()
  private setMailReInput(value: string): void {
    this.mailReInput = value;
  }

  @Emit()
  private setPasswordReInput(value: string): void {
    this.passwordReInput = value;
  }

  @Emit()
  private setNewPasswordReInput(value: string): void {
    this.newPasswordReInput = value;
  }

  @Emit()
  private setSecretQuestAans(value: string): void {
    this.secretQuestAans = value;
  }

  @Emit()
  private setTel1(value: string): void {
    this.tel1 = value;
  }

  @Emit()
  private setTel2(value: string): void {
    this.tel2 = value;
  }

  @Emit()
  private setTel3(value: string): void {
    this.tel3 = value;
  }

  private async beforeRouteEnter(to: Route, from: Route, next: any) {
    const ssFromPath = sessionStorage.getItem('fromPath');
    if (!ssFromPath) {
      let fromPath: string|Array<(string|null)> = from.fullPath;
      if (typeof to.query.from !== 'undefined') {
        fromPath = to.query.from;
      } else if (from.name === 'Login') {
        fromPath = '/';
      }
      sessionStorage.setItem('fromPath', String(fromPath));
    }
    const resCompany = await axios.get('/wp-json/moc/company');
    next((vm: {companyOptions: string[]}) => {
      vm.companyOptions = resCompany.data;
    });
  }

  private beforeRouteLeave(to: Route, from: Route, next: any) {
    store.commit('account/setEditStep', null);
    sessionStorage.removeItem('fromPath');
    // sessionStorage.removeItem('edit_account_start_date');
    next();
  }
}
