




import { Component, Vue } from 'vue-property-decorator';
import VueRouter from 'vue-router';
import AccountForm from '../../plugins/AccountForm';
Component.registerHooks([
  'beforeRouteEnter',
]);

@Component({
  mixins: [
    AccountForm,
  ],
})
export default class AccountCourse extends Vue {
  // private stepNavi: StepNaviData[] = [
  //   {
  //     title: '申込みキャンセル',
  //   },
  //   {
  //     title: '申込み完了',
  //   },
  // ];

  private beforeRouteEnter(to: VueRouter, from: VueRouter, next: any) {
    const status: number = 200;
    const f: any = from;
    let fromPath = f.fullPath;
    if (f.name === 'Login') {
      fromPath = '/';
    }
    next((component: any) => {
      component.fromPath = fromPath;
    });
  }

}
